module.exports = [{
      plugin: require('/builds/vhfmag/vhfmag.gitlab.io/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/builds/vhfmag/vhfmag.gitlab.io/src/components/layout/index.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers"},{"resolve":"gatsby-remark-embedded-codesandbox","options":{"directory":"/builds/vhfmag/vhfmag.gitlab.io","protocol":"codesandbox://","embedOptions":{"view":"split","verticallayout":1,"codemirror":1,"hidenavigation":1,"autoresize":1}}},{"resolve":"gatsby-remark-emoji-unicode"},{"resolve":"gatsby-remark-external-links"},{"resolve":"gatsby-remark-unwrap-images"},{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"withWebp":true}},{"resolve":"gatsby-remark-a11y-emoji"}]},
    },{
      plugin: require('/builds/vhfmag/vhfmag.gitlab.io/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography","omitGoogleFont":true},
    },{
      plugin: require('/builds/vhfmag/vhfmag.gitlab.io/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://yuno.victormagalhaes.codes/matomo","siteUrl":"https://victormagalhaes.codes"},
    },{
      plugin: require('/builds/vhfmag/vhfmag.gitlab.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/vhfmag/vhfmag.gitlab.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
