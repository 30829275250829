// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/vhfmag/vhfmag.gitlab.io/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-tag-list-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/templates/tagList.tsx" /* webpackChunkName: "component---src-templates-tag-list-tsx" */),
  "component---src-templates-entry-list-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/templates/entryList.tsx" /* webpackChunkName: "component---src-templates-entry-list-tsx" */),
  "component---src-templates-single-post-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/templates/singlePost.tsx" /* webpackChunkName: "component---src-templates-single-post-tsx" */),
  "component---src-templates-single-entry-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/templates/singleEntry.tsx" /* webpackChunkName: "component---src-templates-single-entry-tsx" */),
  "component---src-templates-single-presentation-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/templates/singlePresentation.tsx" /* webpackChunkName: "component---src-templates-single-presentation-tsx" */),
  "component---src-pages-404-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-links-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-resume-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-todo-tsx": () => import("/builds/vhfmag/vhfmag.gitlab.io/src/pages/todo.tsx" /* webpackChunkName: "component---src-pages-todo-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/vhfmag/vhfmag.gitlab.io/.cache/data.json")

